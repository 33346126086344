export class Commodity {
  Id: string;
  Name: string;
  Manufacturer: string;
  Type: string;
  Material: string;
  Color: string;
  Dimensions: { [key: string]: number };
  Packings: { [key: string]: { Item1: string; Item2: number } };
  Inactive: boolean;
  Icon: string;
  DisplayColor: string;

  constructor() {
    this.Id = '';
    this.Name = '';
    this.Manufacturer = '';
    this.Type = '';
    this.Material = '';
    this.Color = '';
    this.Dimensions = {};
    this.Packings = {};
    this.Inactive = false;
    this.Icon = '';
    this.DisplayColor = '';
  }
  static EmptyId = '00000000-0000-0000-0000-000000000000';
}

export class CommodityOptions {
  AuthorityId: string[];
  CommodityColor: string[];
  CommodityDimension: string[];
  CommodityMaterial: string[];
  CommodityPacking: string[];
  CommodityType: string[];
  TerminalFlag: string[];

  constructor() {
    this.AuthorityId = [];
    this.CommodityColor = [];
    this.CommodityDimension = [];
    this.CommodityMaterial = [];
    this.CommodityPacking = [];
    this.CommodityType = [];
    this.TerminalFlag = [];
  }
}
