import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00CDC1', //Colors.red.darken1, // #E53935
        secondary: '#635EAF', //Colors.red.lighten4, // #FFCDD2
        accent: '#635EAF', // #3F51B5
        danger: Colors.red.lighten1,
        dangerlight: Colors.red.lighten5,
        secondarylight: Colors.deepPurple.lighten3,
        tertiary: Colors.amber.lighten2,
        tertiarylight: Colors.amber.lighten4,
        tourbackground: Colors.grey.lighten4,
      },
    },
  },
});
