export class Terminal {
  Id: string;
  ShortId: string;
  Name: string;
  Workgroup: number;
  AuthorityId: string;
  Flags: string[];
  LocationId: string;
  InputShelfId: string;
  OutputShelfId: string;
  Limited: boolean;

  constructor() {
    this.Id = '';
    this.ShortId = '';
    this.Name = '';
    this.Workgroup = 0;
    this.AuthorityId = Terminal.Admin;
    this.Flags = [];
    this.LocationId = '';
    this.InputShelfId = '';
    this.OutputShelfId = '';
    this.Limited = false;
  }

  public static readonly Admin = 'Administration';
}

export class Credentials {
  terminalIDU: string;
  terminalIDP: string;
  companyID: string;
  region: string;

  constructor() {
    this.terminalIDU = '';
    this.terminalIDP = '';
    this.companyID = '';
    this.region = '';
  }
}
