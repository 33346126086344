import { Customer } from '@/models/customer.model';
import { HttpService } from './http.service';

export class CustomerService extends HttpService {
  public async ListCustomers(): Promise<Customer[]> {
    return await this.get<Customer[]>('/Customers/', []);
  }

  public async AddCustomer(customer: Customer): Promise<Customer> {
    return await this.put<Customer, Customer>('/Customers/', [], customer);
  }

  public async UpdateCustomer(customer: Customer): Promise<string> {
    return await this.put<string, Customer>('/Customers/Update/', [], customer);
  }

  public async DeleteCustomer(customerId: string): Promise<string> {
    return await this.delete('/Customers/', [['CustomerId', customerId]]);
  }
}
