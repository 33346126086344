import { Module } from 'vuex';
import { TerminalService } from '@/services/terminal.service';
import { Terminal } from '@/models/terminal.model';

export interface ITerminalModule {
  authorities: string[];
  flags: string[];
  terminals: Terminal[];
  terminalsLoading: boolean;
  user: Terminal;
}
const terminalMutations = {
  setTerminals: 'setTerminals',
  setTerminalsLoading: 'setTerminalsLoading',
  setUser: 'setUser',
};
const terminalActions = {
  fetchTerminals: 'fetchTerminals',
  removeTerminal: 'removeTerminal',
  upsertTerminal: 'upsertTerminal',
  setUserManually: 'setUserManually',
};

const cs = new TerminalService();
const terminalModule: Module<ITerminalModule, any> = {
  state: {
    authorities: [] as string[],
    flags: [] as string[],
    terminals: [] as Terminal[],
    terminalsLoading: false as boolean,
  } as ITerminalModule,
  mutations: {
    [terminalMutations.setTerminals](
      state,
      reply: {
        Terminals: Terminal[];
        Authorities: string[];
        TerminalFlags: string[];
      }
    ) {
      state.terminals = reply.Terminals.sort((a, b) =>
        a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase())
      );
      state.authorities = reply.Authorities;
      state.flags = reply.TerminalFlags;
    },
    [terminalMutations.setTerminalsLoading](state, isLoading: boolean) {
      state.terminalsLoading = isLoading;
    },
    [terminalMutations.setUser](state, user: Terminal) {
      state.user = user;
    },
  },
  actions: {
    [terminalActions.fetchTerminals]({ commit }) {
      commit(terminalMutations.setTerminalsLoading, true);
      cs.ListTerminals()
        .then((value) => {
          commit(terminalMutations.setTerminals, value);
          commit(terminalMutations.setTerminalsLoading, false);
        })
        .catch((error) => {
          commit(terminalMutations.setTerminalsLoading, false);
          throw error;
        });
    },
    [terminalActions.removeTerminal]({ dispatch, commit }, terminal: Terminal) {
      commit(terminalMutations.setTerminalsLoading, true);
      if (terminal.Id) {
        cs.DeleteTerminal(terminal.Id)
          .then(() => dispatch(terminalActions.fetchTerminals))
          .catch((error) => {
            commit(terminalMutations.setTerminalsLoading, false);
            throw error;
          });
      }
    },
    [terminalActions.upsertTerminal]({ dispatch, commit }, terminal: Terminal) {
      commit(terminalMutations.setTerminalsLoading, true);
      if (!terminal.Id) {
        cs.AddTerminal(terminal)
          .then(() => dispatch(terminalActions.fetchTerminals))
          .catch((error) => {
            commit(terminalMutations.setTerminalsLoading, false);
            throw error;
          });
      } else {
        cs.UpdateTerminal(terminal)
          .then(() => dispatch(terminalActions.fetchTerminals))
          .catch((error) => {
            commit(terminalMutations.setTerminalsLoading, false);
            throw error;
          });
      }
    },
    [terminalActions.setUserManually]({ commit }, terminal: Terminal) {
      commit(terminalMutations.setUser, terminal);
    },
  },
};

export default terminalModule;
export { terminalActions };
