import {
  Component,
  OptimizationConfiguration,
  Product,
} from '@/models/configuration.model';
import { HttpService } from './http.service';

export class ConfigurationService extends HttpService {
  public async ListComponents(): Promise<Component[]> {
    return await this.get<Component[]>('/ComponentConfigurations/', []);
  }

  public async AddComponent(Configuration: Component): Promise<Component> {
    return await this.put<Component, Component>(
      '/ComponentConfigurations/',
      [],
      Configuration
    );
  }

  public async UpdateComponent(Configuration: Component): Promise<string> {
    return await this.put<string, Component>(
      '/ComponentConfigurations/Update/',
      [],
      Configuration
    );
  }

  public async DeleteComponent(ConfigurationId: string): Promise<string> {
    return await this.delete('/ComponentConfigurations/', [
      ['ComponentConfigurationId', ConfigurationId],
    ]);
  }

  public async ListOptimizationConfiguration(): Promise<
    OptimizationConfiguration[]
  > {
    return await this.get<OptimizationConfiguration[]>(
      '/OptimizationConfiguration/',
      []
    );
  }

  public async AddOptimizationConfiguration(
    op: OptimizationConfiguration
  ): Promise<OptimizationConfiguration> {
    return await this.put<OptimizationConfiguration, OptimizationConfiguration>(
      '/OptimizationConfiguration/',
      [],
      op
    );
  }

  public async UpdateOptimizationConfiguration(
    op: OptimizationConfiguration
  ): Promise<string> {
    return await this.put<string, OptimizationConfiguration>(
      '/OptimizationConfiguration/Update/',
      [],
      op
    );
  }

  public async DeleteOptimizationConfiguration(
    op: OptimizationConfiguration
  ): Promise<string> {
    return await this.put<string, OptimizationConfiguration>(
      '/OptimizationConfiguration/Delete/',
      [],
      op
    );
  }

  public async ListProducts(): Promise<Product[]> {
    return await this.get<Product[]>('/ProductConfigurations/', []);
  }

  public async AddProduct(Configuration: Product): Promise<Product> {
    return await this.put<Product, Product>(
      '/ProductConfigurations/',
      [],
      Configuration
    );
  }

  public async UpdateProduct(Configuration: Product): Promise<string> {
    return await this.put<string, Product>(
      '/ProductConfigurations/Update/',
      [],
      Configuration
    );
  }

  public async DeleteProduct(ConfigurationId: string): Promise<string> {
    return await this.delete('/ProductConfigurations/', [
      ['ProductConfigurationId', ConfigurationId],
    ]);
  }

  public static ValidateFormula(formula: string, variables: string[]): boolean {
    let toEval = formula;
    if (!formula) {
      return false;
    }
    variables.forEach((v) => {
      toEval = toEval.replaceAll(
        new RegExp(`^(${v})([^a-zA-Z0-9])`, 'g'),
        '10$2'
      );
      toEval = toEval.replaceAll(
        new RegExp(`([^a-zA-Z0-9])(${v})([^a-zA-Z0-9])`, 'g'),
        '$110$3'
      );
      toEval = toEval.replaceAll(
        new RegExp(`([^a-zA-Z0-9])(${v})$`, 'g'),
        '$110'
      );
      toEval = toEval.replaceAll(new RegExp(`^(${v})$`, 'g'), '10');
    });
    try {
      const ret = eval(toEval);
      if ((ret || ret === 0) && ret != 'NaN') {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}
