import { Customer } from './customer.model';
import { Commodity } from './commodity.model';
import { Component, Product } from './configuration.model';

export const ProcessingState = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'COMPLETED',
  'FAILED',
  'UNLOCKING',
  'UNLOCKED',
];

export class ShiftStatus {
  Id: string;
  Datetime: string;
  ProcessingState: number;
  Message: string;
  OperationsString: string;
  ReservedStorablesString: string;
  CreatedStorablesString: string;
  IsLocked: boolean;
  Products: number;
  Components: number;

  constructor() {
    this.Id = '';
    this.Datetime = '';
    this.ProcessingState = 0;
    this.Message = '';
    this.OperationsString = '';
    this.ReservedStorablesString = '';
    this.CreatedStorablesString = '';
    this.IsLocked = false;
    this.Products = 0;
    this.Components = 0;
  }

  public static getShiftList() {
    return [
      { name: 'I', value: 'T00:00:00' },
      { name: 'II', value: 'T08:00:00' },
      { name: 'III', value: 'T16:00:00' },
    ];
  }
}

export class Order {
  Id: string;
  Comment: string;
  CustomerId: string;
  OrderId: number;
  OrderName: string;
  ProductConfigurationId: string;
  ConfigurationParameterValues: { [key: string]: string };
  ProductionDate: string;
  ComponentDates: { [key: string]: string };
  Packaging: boolean;
  isVisible: boolean;

  constructor() {
    this.Id = '';
    this.Comment = '';
    this.CustomerId = '';
    this.OrderId = 0;
    this.OrderName = '';
    this.ProductConfigurationId = '';
    this.ConfigurationParameterValues = { Width: '0', Height: '0' };
    this.ProductionDate = '1970-01-01';
    this.ComponentDates = {};
    this.Packaging = false;
    this.isVisible = false;
  }

  public static GetVisibleId(order: Order): string {
    if (!order || !order.Id) {
      return '';
    }
    const productionDate = new Date(order.ProductionDate);
    const shift = productionDate.getHours() / 8 + 1;
    const shiftSegment = ''.padStart(shift, 'I');
    const orderIdSegment = ('' + order.OrderId).padStart(3, '0');
    let dateSegment = productionDate.toLocaleString('hu', {
      year: '2-digit',
      month: 'short',
      day: '2-digit',
    });
    dateSegment = dateSegment.replaceAll('.', '');
    dateSegment = dateSegment.replaceAll(' ', '');
    dateSegment = dateSegment.toUpperCase();
    return `${dateSegment}-${shiftSegment}-${orderIdSegment}`;
  }
}

export class OrderReadiness {
  Id: string;
  Started: boolean;
  Done: boolean;

  constructor() {
    this.Id = '';
    this.Started = false;
    this.Done = false;
  }
}

export class OrderAux {
  Commodities: Commodity[] = [];
  CommodityColors: string[] = [];
  CommodityDimensions: string[] = [];
  Components: Component[] = [];
  Customers: Customer[] = [];
  Products: Product[] = [];
}

export interface ICalendarEvent {
  name: string;
  start: Date;
  timed: boolean;
  color: string;
  original: ShiftStatus;
}
