<template>
  <v-app id="app">
    <v-card style="position: fixed">
      <div class="pa-2">{{ $t('VersionNumber') }} {{ version }}</div>
    </v-card>
    <router-view />
    <v-snackbar top fixed :value="updateExists" :timeout="-1">
      {{ $t('UpdateAvailable') }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="refreshApp" color="danger" v-bind="attrs">
          {{ $t('Update') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { version } from '../package.json';

export default Vue.extend({
  name: 'App',
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  data: () => ({
    updateExists: false,
    refreshing: false,
    registration: {} as ServiceWorkerRegistration,
  }),
  computed: {
    version() {
      return version;
    },
  },
  methods: {
    updateAvailable(event: Event) {
      console.log({ updateEvent: event });
      this.registration = (
        event as Event & { detail: ServiceWorkerRegistration }
      ).detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      // window.location.reload();
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
});
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap'); */

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'), format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

#app {
  font-family: Rubik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('assets/background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
  left: 0px;
  top: 0px;
}

.minimumFormulaFieldSize {
  min-width: 100px;
}

.minimumSelectFieldSize {
  min-width: 150px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.mainCard {
  width: 75vw;
}

@media (max-width: 1200px) {
  .mainCard {
    width: 99%;
  }
}

.svgIcon {
  width: 24px;
  height: 24px;
}
</style>
