import { Module } from 'vuex';
import {
  Component,
  OptimizationConfiguration,
  Product,
} from '@/models/configuration.model';
import { ConfigurationService } from '@/services/configuration.service';

export interface IConfigurationModule {
  components: Component[];
  componentLoading: boolean;
  optimizations: OptimizationConfiguration[];
  optimizationsLoading: boolean;
  products: Product[];
  productLoading: boolean;
}
const configurationMutations = {
  setComponents: 'setComponents',
  setComponentLoading: 'setComponentLoading',
  setOptimizations: 'setOptimizations',
  setOptimizationsLoading: 'setOptimizationsLoading',
  setProducts: 'setProducts',
  setProductLoading: 'setProductLoading',
};
const configurationActions = {
  fetchComponents: 'fetchComponents',
  fetchOptimizations: 'fetchOptimizations',
  fetchProducts: 'fetchProducts',
  removeComponent: 'removeComponent',
  removeOptimizations: 'removeOptimizations',
  removeProduct: 'removeProduct',
  upsertComponent: 'upsertComponent',
  upsertOptimizations: 'upsertOptimizations',
  upsertProduct: 'upsertProduct',
};

const cs = new ConfigurationService();
const configurationModule: Module<IConfigurationModule, any> = {
  state: {
    components: [] as Component[],
    componentLoading: false,
    optimizations: [] as OptimizationConfiguration[],
    optimizationsLoading: false,
    products: [] as Product[],
    productLoading: false,
  } as IConfigurationModule,
  mutations: {
    [configurationMutations.setComponents](state, components: Component[]) {
      state.components = components.sort((a, b) =>
        a.ComponentName.toLocaleLowerCase().localeCompare(
          b.ComponentName.toLocaleLowerCase()
        )
      );
    },
    [configurationMutations.setComponentLoading](state, loading: boolean) {
      state.componentLoading = loading;
    },
    [configurationMutations.setOptimizations](
      state,
      optimizations: OptimizationConfiguration[]
    ) {
      state.optimizations = optimizations;
    },
    [configurationMutations.setOptimizationsLoading](state, loading: boolean) {
      state.optimizationsLoading = loading;
    },
    [configurationMutations.setProducts](state, products: Product[]) {
      state.products = products.sort((a, b) =>
        a.ProductName.toLocaleLowerCase().localeCompare(
          b.ProductName.toLocaleLowerCase()
        )
      );
    },
    [configurationMutations.setProductLoading](state, loading: boolean) {
      state.productLoading = loading;
    },
  },
  actions: {
    [configurationActions.fetchComponents]({ commit }) {
      commit(configurationMutations.setComponentLoading, true);
      cs.ListComponents()
        .then((value) => {
          commit(configurationMutations.setComponents, value);
          commit(configurationMutations.setComponentLoading, false);
        })
        .catch((error) => {
          commit(configurationMutations.setComponentLoading, false);
          throw error;
        });
    },
    [configurationActions.fetchOptimizations]({ commit }) {
      commit(configurationMutations.setOptimizationsLoading, true);
      cs.ListOptimizationConfiguration()
        .then((value) => {
          commit(configurationMutations.setOptimizations, value);
          commit(configurationMutations.setOptimizationsLoading, false);
        })
        .catch((error) => {
          commit(configurationMutations.setOptimizationsLoading, false);
          throw error;
        });
    },
    [configurationActions.fetchProducts]({ commit }) {
      commit(configurationMutations.setProductLoading, true);
      cs.ListProducts()
        .then((value) => {
          commit(configurationMutations.setProducts, value);
          commit(configurationMutations.setProductLoading, false);
        })
        .catch((error) => {
          commit(configurationMutations.setProductLoading, false);
          throw error;
        });
    },
    [configurationActions.removeComponent](
      { commit, dispatch },
      component: Component
    ) {
      commit(configurationMutations.setComponentLoading, true);
      cs.DeleteComponent(component.Id)
        .then(() => dispatch(configurationActions.fetchComponents))
        .catch((error) => {
          commit(configurationMutations.setComponentLoading, false);
          throw error;
        });
    },
    [configurationActions.removeOptimizations](
      { commit, dispatch },
      optimization: OptimizationConfiguration
    ) {
      commit(configurationMutations.setOptimizationsLoading, true);
      cs.DeleteOptimizationConfiguration(optimization)
        .then(() => dispatch(configurationActions.fetchOptimizations))
        .catch((error) => {
          commit(configurationMutations.setOptimizationsLoading, false);
          throw error;
        });
    },
    [configurationActions.removeProduct](
      { commit, dispatch },
      product: Product
    ) {
      commit(configurationMutations.setProductLoading, true);
      cs.DeleteProduct(product.Id)
        .then(() => dispatch(configurationActions.fetchProducts))
        .catch((error) => {
          commit(configurationMutations.setProductLoading, false);
          throw error;
        });
    },
    [configurationActions.upsertComponent](
      { commit, dispatch },
      component: Component
    ) {
      commit(configurationMutations.setComponentLoading, true);
      if (!component.Id) {
        cs.AddComponent(component)
          .then(() => dispatch(configurationActions.fetchComponents))
          .catch((error) => {
            commit(configurationMutations.setComponentLoading, false);
            throw error;
          });
      } else {
        cs.UpdateComponent(component)
          .then(() => dispatch(configurationActions.fetchComponents))
          .catch((error) => {
            commit(configurationMutations.setComponentLoading, false);
            throw error;
          });
      }
    },
    [configurationActions.upsertOptimizations](
      { commit, dispatch },
      optimization: OptimizationConfiguration
    ) {
      commit(configurationMutations.setOptimizationsLoading, true);
      if (optimization.Id === -1) {
        cs.AddOptimizationConfiguration(optimization)
          .then(() => dispatch(configurationActions.fetchOptimizations))
          .catch((error) => {
            commit(configurationMutations.setOptimizationsLoading, false);
            throw error;
          });
      } else {
        cs.UpdateOptimizationConfiguration(optimization)
          .then(() => dispatch(configurationActions.fetchOptimizations))
          .catch((error) => {
            commit(configurationMutations.setOptimizationsLoading, false);
            throw error;
          });
      }
    },
    [configurationActions.upsertProduct](
      { commit, dispatch },
      product: Product
    ) {
      commit(configurationMutations.setProductLoading, true);
      if (!product.Id) {
        cs.AddProduct(product)
          .then(() => dispatch(configurationActions.fetchProducts))
          .catch((error) => {
            commit(configurationMutations.setProductLoading, false);
            throw error;
          });
      } else {
        cs.UpdateProduct(product)
          .then(() => dispatch(configurationActions.fetchProducts))
          .catch((error) => {
            commit(configurationMutations.setProductLoading, false);
            throw error;
          });
      }
    },
  },
};

export default configurationModule;
export { configurationActions };
