import {
  ManufactureLocation,
  PromisedStorable,
  Storable,
} from '@/models/storage.model';
import { HttpService } from './http.service';

export class StorageService extends HttpService {
  public async GetLocations(): Promise<ManufactureLocation[]> {
    return await this.get<ManufactureLocation[]>('/Locations', []);
  }

  public async AddLocation(locationName: string): Promise<string> {
    locationName = encodeURIComponent(locationName);
    return await this.put<string, string>(
      '/Locations',
      [['locationName', locationName]],
      ''
    );
  }

  public async UpdateLocation(options: {
    locationId: string;
    name: string;
  }): Promise<string> {
    options.name = encodeURIComponent(options.name);
    return await this.put<string, string>(
      '/Locations/Update',
      [
        ['locationId', options.locationId],
        ['newName', options.name],
      ],
      ''
    );
  }

  public async RemoveLocation(locationId: string): Promise<string> {
    return await this.delete('/Locations', [['locationId', locationId]]);
  }

  public async AddShelf(options: {
    locationId: string;
    shelfName: string;
  }): Promise<string> {
    options.shelfName = encodeURIComponent(options.shelfName);
    return await this.put<string, string>(
      '/Shelves',
      [
        ['locationId', options.locationId],
        ['shelfName', options.shelfName],
      ],
      ''
    );
  }

  public async UpdateShelf(options: {
    shelfId: string;
    locationId: string;
    name: string;
  }): Promise<string> {
    options.name = encodeURIComponent(options.name);
    return await this.put<string, string>(
      '/Shelves/Update',
      [
        ['shelfId', options.shelfId],
        ['locationId', options.locationId],
        ['newName', options.name],
      ],
      ''
    );
  }

  public async RemoveShelf(shelfId: string): Promise<string> {
    return await this.delete('/Shelves', [['shelfId', shelfId]]);
  }

  public async GetItemsById(itemUuids: string[]): Promise<Storable[]> {
    return await this.put<Storable[], string[]>('/Storables/Id', [], itemUuids);
  }

  public async GetItemsByFilter(options: {
    filter: string;
    unresolved: boolean;
  }): Promise<Storable[]> {
    return await this.put<Storable[], string>(
      '/Storables/Filter',
      [
        ['filter', options.filter],
        ['resolved', options.unresolved ? '0' : '1'],
      ],
      ''
    );
  }

  public async GetPromisedItems(): Promise<PromisedStorable[]> {
    return await this.get<PromisedStorable[]>('/Storables/Promised', []);
  }

  public async AddItem(options: {
    locationId: string;
    shelfId: string;
    storable: Storable;
    count: number;
  }): Promise<string> {
    return await this.put<string, Storable>(
      '/Storables',
      [
        ['locationId', options.locationId],
        ['shelfId', options.shelfId],
        ['count', `${options.count}`],
      ],
      options.storable
    );
  }

  public async MoveItems(target: {
    destination: string;
    storable: Storable;
    qtty: number;
  }): Promise<string> {
    return await this.put<string, Storable>(
      '/Storables/Move',
      [
        ['destination', target.destination],
        ['qtty', '' + target.qtty],
      ],
      target.storable
    );
  }

  public async RemoveItems(target: {
    storable: Storable;
    qtty: number;
  }): Promise<string> {
    return await this.put<string, Storable>(
      '/Storables/Delete',
      [['qtty', '' + target.qtty]],
      target.storable
    );
  }

  public async RemoveJunk(): Promise<string[]> {
    return await this.put<string[], string>('/Storables/Junk', [], '');
  }
}
