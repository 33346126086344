import { Commodity, CommodityOptions } from '@/models/commodity.model';
import { HttpService } from './http.service';

export class CommodityService extends HttpService {
  public async GetOptions(): Promise<CommodityOptions> {
    return await this.get<CommodityOptions>('/GetOptions', []);
  }

  public async ListCommodities(): Promise<Commodity[]> {
    return await this.get<Commodity[]>('/Commodities/', []);
  }

  public async AddCommodity(commodity: Commodity): Promise<Commodity> {
    return await this.put<Commodity, Commodity>('/Commodities/', [], commodity);
  }

  public async UpdateCommodity(commodity: Commodity): Promise<string> {
    return await this.put<string, Commodity>(
      '/Commodities/Update/',
      [],
      commodity
    );
  }

  public async DeleteCommodity(commodityId: string): Promise<string> {
    return await this.delete('/Commodities/', [['CommodityId', commodityId]]);
  }
}
