import {
  ShiftStatus,
  Order,
  OrderAux,
  OrderReadiness,
} from '@/models/order.model';
import { HttpService } from './http.service';

export class OrderService extends HttpService {
  public async GetAux(): Promise<OrderAux> {
    return await this.get<OrderAux>('/Orders/Aux/', []);
  }

  public async GetShift(year: number, month: number): Promise<ShiftStatus[]> {
    return await this.get<ShiftStatus[]>('/Orders/Shifts/', [
      ['year', `${year}`],
      ['month', `${month}`],
    ]);
  }

  public async GetOrdersOfCustomer(
    customer: string,
    yearmonth: string
  ): Promise<Order[]> {
    console.log({ customer, yearmonth });

    return await this.get<Order[]>('/Orders/Customer', [
      ['customer', customer],
      ['yearmonth', yearmonth],
    ]);
  }

  public async GetOrdersOfShift(date: string): Promise<Order[]> {
    return await this.get<Order[]>('/Orders/Shift', [['ordersShift', date]]);
  }

  public async GetRelevantOrdersOfShift(date: string): Promise<Order[]> {
    return await this.get<Order[]>('/Orders/RelevantForShift', [
      ['ordersShift', date],
    ]);
  }

  async GetOrdersReadiness(orderIds: string[]): Promise<OrderReadiness[]> {
    return await this.put<OrderReadiness[], string[]>(
      '/Orders/Readiness',
      [],
      orderIds
    );
  }

  public async AddOrder(Order: Order): Promise<Order> {
    return await this.put<Order, Order>('/Orders/', [], Order);
  }

  public async UpdateOrder(Order: Order): Promise<string> {
    return await this.put<string, Order>('/Orders/Update/', [], Order);
  }

  public async DeleteOrder(OrderId: string): Promise<string> {
    return await this.delete('/Orders/', [['OrderId', OrderId]]);
  }

  public async LockDate(target: {
    shift: string;
    lockStatus: boolean;
  }): Promise<string> {
    return await this.put(
      '/Orders/Lock/',
      [
        ['targetDateTime', target.shift],
        ['lock', target.lockStatus ? 'false' : 'true'],
      ],
      ''
    );
  }
}
