import { Module } from 'vuex';
import { StorageService } from '@/services/storage.service';
import {
  ManufactureLocation,
  PromisedStorable,
  Storable,
} from '@/models/storage.model';

export interface IStorageModule {
  locations: ManufactureLocation[];
  storableFilter: { filter: string; unresolved: boolean };
  storables: Storable[];
  storablesFiltered: Storable[];
  promisedStorables: PromisedStorable[];
  storageLoading: boolean;
}
const storageMutations = {
  setLocations: 'setLocations',
  setStorableFilter: 'setStorableFilter',
  setStorables: 'setStorables',
  setPromisedStorables: 'setPromisedStorables',
  setStorageLoading: 'setStorageLoading',
};
const storageActions = {
  addLocation: 'addLocation',
  addShelf: 'addShelf',
  addStorable: 'addStorable',
  fetchLocations: 'fetchLocations',
  fetchStorables: 'fetchStorables',
  moveStorables: 'moveStorables',
  removeJunk: 'removeJunk',
  removeLocation: 'removeLocation',
  removeShelf: 'removeShelf',
  removeStorables: 'removeStorables',
  renameLocation: 'renameLocation',
  renameShelf: 'renameShelf',
};

const service = new StorageService();
const storageModule: Module<IStorageModule, any> = {
  state: {
    locations: [] as ManufactureLocation[],
    storableFilter: { filter: '', unresolved: false } as {
      filter: string;
      unresolved: boolean;
    },
    storables: [] as Storable[],
    storablesFiltered: [] as Storable[],
    promisedStorables: [] as PromisedStorable[],
    storageLoading: false,
  } as IStorageModule,
  mutations: {
    [storageMutations.setLocations](state, locations: ManufactureLocation[]) {
      state.locations = locations.sort((a, b) =>
        a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase())
      );
    },
    [storageMutations.setStorableFilter](
      state,
      options: { filter: string; unresolved: boolean }
    ) {
      state.storableFilter = options;
    },
    [storageMutations.setStorables](state, storables: Storable[]) {
      state.storables = storables.sort((a, b) =>
        a.ReferenceName.toLocaleLowerCase().localeCompare(
          b.ReferenceName.toLocaleLowerCase()
        )
      );
      const filterword = state.storableFilter.filter.toLocaleLowerCase();
      state.storablesFiltered = state.storables.filter((st) =>
        st.ReferenceName.toLocaleLowerCase().includes(filterword)
      );
    },
    [storageMutations.setPromisedStorables](
      state,
      promisedStorables: PromisedStorable[]
    ) {
      state.promisedStorables = promisedStorables.sort((a, b) =>
        a.ReferenceName.toLocaleLowerCase().localeCompare(
          b.ReferenceName.toLocaleLowerCase()
        )
      );
    },
    [storageMutations.setStorageLoading](state, isLoading: boolean) {
      state.storageLoading = isLoading;
    },
  },
  actions: {
    [storageActions.addLocation]({ commit, dispatch }, name: string) {
      commit(storageMutations.setStorageLoading, true);
      service
        .AddLocation(name)
        .then(() => {
          dispatch(storageActions.fetchLocations, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.addShelf](
      { commit, dispatch },
      options: {
        locationId: string;
        shelfName: string;
      }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .AddShelf(options)
        .then(() => {
          dispatch(storageActions.fetchLocations, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.addStorable](
      { commit, dispatch },
      options: {
        locationId: string;
        shelfId: string;
        storable: Storable;
        count: number;
      }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .AddItem(options)
        .then(() => {
          dispatch(storageActions.fetchStorables, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.fetchLocations]({ commit }) {
      commit(storageMutations.setStorageLoading, true);
      service
        .GetLocations()
        .then((value) => {
          commit(storageMutations.setLocations, value);
          commit(storageMutations.setStorageLoading, false);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.fetchStorables](
      { commit, state },
      options: { filter: string; unresolved: boolean }
    ) {
      if (options != null) {
        commit(storageMutations.setStorableFilter, options);
      }
      commit(storageMutations.setStorageLoading, true);
      service
        .GetItemsByFilter({
          filter: '',
          unresolved: state.storableFilter.unresolved,
        })
        .then((storables) => {
          commit(storageMutations.setStorables, storables);
          service
            .GetPromisedItems()
            .then((promisedStorables) => {
              commit(storageMutations.setPromisedStorables, promisedStorables);
              commit(storageMutations.setStorageLoading, false);
            })
            .catch((error) => {
              commit(storageMutations.setStorageLoading, false);
              throw error;
            });
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.moveStorables](
      { commit, dispatch },
      target: { destination: string; storable: Storable; qtty: number }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .MoveItems(target)
        .then(() => {
          dispatch(storageActions.fetchStorables, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.removeJunk]({ commit, dispatch }) {
      commit(storageMutations.setStorageLoading, true);
      service
        .RemoveJunk()
        .then(() => {
          dispatch(storageActions.fetchStorables, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.removeLocation]({ commit, dispatch }, locationId: string) {
      commit(storageMutations.setStorageLoading, true);
      service
        .RemoveLocation(locationId)
        .then(() => {
          dispatch(storageActions.fetchLocations);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.removeShelf]({ commit, dispatch }, shelfId: string) {
      commit(storageMutations.setStorageLoading, true);
      service
        .RemoveShelf(shelfId)
        .then(() => {
          dispatch(storageActions.fetchLocations);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.removeStorables](
      { commit, dispatch },
      target: { storable: Storable; qtty: number }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .RemoveItems(target)
        .then(() => {
          dispatch(storageActions.fetchStorables, null);
        })
        .catch((error) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.renameLocation](
      { commit, dispatch },
      options: {
        locationId: string;
        name: string;
      }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .UpdateLocation(options)
        .then(() => {
          dispatch(storageActions.fetchLocations, null);
        })
        .catch((error: unknown) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
    [storageActions.renameShelf](
      { commit, dispatch },
      options: {
        shelfId: string;
        locationId: string;
        name: string;
      }
    ) {
      commit(storageMutations.setStorageLoading, true);
      service
        .UpdateShelf(options)
        .then(() => {
          dispatch(storageActions.fetchLocations, null);
        })
        .catch((error: unknown) => {
          commit(storageMutations.setStorageLoading, false);
          throw error;
        });
    },
  },
};

export default storageModule;
export { storageActions };
