import { Terminal } from '@/models/terminal.model';
import { HttpService } from './http.service';

export class TerminalService extends HttpService {
  public async ListTerminals(): Promise<{
    Terminals: Terminal[];
    Authorities: string[];
    TerminalFlags: string[];
  }> {
    return await this.get<{
      Terminals: Terminal[];
      Authorities: string[];
      TerminalFlags: string[];
    }>('/Terminals/', []);
  }

  public async AddTerminal(Terminal: Terminal): Promise<Terminal> {
    return await this.put<Terminal, Terminal>('/Terminals/', [], Terminal);
  }

  public async UpdateTerminal(Terminal: Terminal): Promise<string> {
    return await this.put<string, Terminal>('/Terminals/Update/', [], Terminal);
  }

  public async DeleteTerminal(TerminalId: string): Promise<string> {
    return await this.delete('/Terminals/', [['TerminalId', TerminalId]]);
  }
}
