import { MonthStatistics, StatisticsMember } from '@/models/statistics.model';
import { HttpService } from './http.service';

export class StatisticsService extends HttpService {
  public async GetStatistics(): Promise<StatisticsMember[]> {
    return await this.put<StatisticsMember[], string>('/Statistics/', [], '');
  }

  public async GetStatisticsOfDate(date: string): Promise<StatisticsMember[]> {
    return await this.put<StatisticsMember[], string>(
      '/StatisticsOD/',
      [['StatisticsDate', date]],
      ''
    );
  }

  public async GetOrderStatistics(filter: string): Promise<MonthStatistics[]> {
    return await this.put<MonthStatistics[], string>(
      '/OrderStatistics/',
      [['SearchString', encodeURIComponent(filter)]],
      ''
    );
  }
}
