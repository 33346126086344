<template>
  <v-card class="ma-auto pa-8 rounded-lg" elevation="20">
    <v-row>
      <v-img class="mx-6" width="200" src="../assets/Logo_V2.1.svg" />
    </v-row>
    <v-row class="mx-6">
      <v-form>
        <v-container>
          <v-row>
            <v-text-field
              :label="$t('Login.Identifier')"
              prepend-icon="mdi-account"
              :messages="validatorMessages.login"
              v-model="credentials.terminalIDU"
            />
          </v-row>
          <v-row>
            <v-text-field
              append-icon="mdi-lock-reset"
              :label="$t('Login.Password')"
              prepend-icon="mdi-lock"
              type="password"
              :messages="validatorMessages.pass"
              :readonly="userPassDisabled"
              v-model="credentials.terminalIDP"
              @click:append="userPassDisabled = !userPassDisabled"
            />
          </v-row>
          <v-row>
            <v-select
              prepend-icon="mdi-translate"
              v-model="language"
              :items="languages"
              item-value="name"
            >
              <template #item="{ item }">
                <v-avatar left size="24" class="mr-4">
                  <v-img :src="item.icon" />
                </v-avatar>
                {{ item.name }}
              </template>
              <template #selection="{ item }">
                <v-avatar left size="24" class="mr-4">
                  <v-img :src="item.icon" />
                </v-avatar>
                {{ item.name }}
              </template>
            </v-select>
          </v-row>
          <v-row>
            <v-btn
              class="rounded-l-sm rounded-r-xl ml-auto"
              @click="login"
              color="primary"
            >
              {{ $t('Login.Login') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { Credentials, Terminal } from '@/models/terminal.model';
import { HttpService } from '@/services/http.service';
import { terminalActions } from '@/store/terminal.store';

interface Language {
  name: string;
  icon: string;
}

export default Vue.extend({
  name: 'LoginView',
  components: {},
  data() {
    return {
      credentials: new Credentials(),
      database: [] as IDBDatabase[],
      httpService: {} as HttpService,
      language: '',
      languages: [
        { name: 'HU', icon: 'https://flagcdn.com/hu.svg' },
        { name: 'EN', icon: 'https://flagcdn.com/us.svg' },
        { name: 'DE', icon: 'https://flagcdn.com/de.svg' },
      ] as Language[],
      userPassDisabled: false,
      validatorMessages: {
        login: [] as string[],
        pass: [] as string[],
      },
    };
  },
  mounted() {
    this.httpService = new HttpService();
    localStorage.getItem('language') === null
      ? (this.language = 'HU')
      : (this.language = localStorage.getItem('language') as string);
  },
  methods: {
    async getDatabase() {
      return new Promise((resolve, reject) => {
        if (this.database.length === 1) {
          resolve(this.database[0]);
        }

        const request = window.indexedDB.open('manufactureUser', 1);

        request.onerror = (event) => {
          console.error('Error: Unable to open database', event);
          reject('Error');
        };

        request.onsuccess = () => {
          this.database.push(request.result);
          resolve(this.database[0]);
        };
      });
    },
    login() {
      this.httpService.setCredentials(this.credentials);
      this.httpService
        .authenticate()
        .then((value: Terminal) => {
          this.$store.dispatch(terminalActions.setUserManually, value);
          const role = HttpService.getTerminalRole();
          if (role === 'Administration') {
            this.$router.push('AdminOverview').catch(() => {
              console.log('Guarded');
            });
          } else if (role === 'WarehouseManagement') {
            this.$router.push('WarehouseOverview').catch(() => {
              console.log('Guarded');
            });
          } else {
            this.$router.push('OperationList').catch(() => {
              console.log('Guarded');
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    language() {
      localStorage.setItem('language', this.language);
      this.$i18n.locale = this.language.toLocaleLowerCase();
    },
  },
});
</script>
