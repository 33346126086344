import { Module } from 'vuex';
import { Commodity, CommodityOptions } from '@/models/commodity.model';
import { CommodityService } from '@/services/commodity.service';

export interface ICommodityModule {
  commodities: Commodity[];
  commoditiesLoading: boolean;
  options: CommodityOptions;
}
const commodityMutations = {
  setCommodities: 'setCommodities',
  setCommoditiesLoading: 'setCommoditiesLoading',
  setOptions: 'setOptions',
};
const commodityActions = {
  fetchCommodities: 'fetchCommodities',
  fetchOptions: 'fetchOptions',
  removeCommodity: 'removeCommodity',
  upsertCommodity: 'upsertCommodity',
};

const cs = new CommodityService();
const commodityModule: Module<ICommodityModule, any> = {
  state: {
    commodities: [] as Commodity[],
    commoditiesLoading: false as boolean,
    options: new CommodityOptions() as CommodityOptions,
  } as ICommodityModule,
  mutations: {
    [commodityMutations.setCommodities](state, commodities: Commodity[]) {
      state.commodities = commodities.sort((a, b) =>
        a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase())
      );
    },
    [commodityMutations.setCommoditiesLoading](state, isLoading: boolean) {
      state.commoditiesLoading = isLoading;
    },
    [commodityMutations.setOptions](state, options) {
      state.options = options;
    },
  },
  actions: {
    [commodityActions.fetchCommodities]({ commit }) {
      commit(commodityMutations.setCommoditiesLoading, true);
      cs.ListCommodities()
        .then((value) => {
          commit(commodityMutations.setCommodities, value);
          commit(commodityMutations.setCommoditiesLoading, false);
        })
        .catch((error) => {
          commit(commodityMutations.setCommoditiesLoading, false);
          throw error;
        });
    },
    [commodityActions.fetchOptions]({ commit }) {
      cs.GetOptions()
        .then((value) => {
          commit(commodityMutations.setOptions, value);
        })
        .catch((error) => {
          throw error;
        });
    },
    [commodityActions.removeCommodity](
      { dispatch, commit },
      commodity: Commodity
    ) {
      commit(commodityMutations.setCommoditiesLoading, true);
      if (commodity.Id) {
        cs.DeleteCommodity(commodity.Id)
          .then(() => dispatch(commodityActions.fetchCommodities))
          .catch((error) => {
            commit(commodityMutations.setCommoditiesLoading, false);
            throw error;
          });
      }
    },
    [commodityActions.upsertCommodity](
      { dispatch, commit },
      commodity: Commodity
    ) {
      commit(commodityMutations.setCommoditiesLoading, true);
      if (!commodity.Id) {
        cs.AddCommodity(commodity)
          .then(() => dispatch(commodityActions.fetchCommodities))
          .catch((error) => {
            commit(commodityMutations.setCommoditiesLoading, false);
            throw error;
          });
      } else {
        cs.UpdateCommodity(commodity)
          .then(() => dispatch(commodityActions.fetchCommodities))
          .catch((error) => {
            commit(commodityMutations.setCommoditiesLoading, false);
            throw error;
          });
      }
    },
  },
};

export default commodityModule;
export { commodityActions };
